import React from 'react';
import { RouteComponentProps } from 'react-router';

import { useSelector } from '../../lib/store';
import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';
import { ProfilePsychoTabContainer } from '../../epic/profile-psycho-tab';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { ProfileUserTabContainer } from '../../epic/profile-user-tab';

export const Page: React.FC<RouteComponentProps> = () => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const isUser = state.user?.role === USER_ROLE.USER;
  const isCaretaker = state.user?.role === USER_ROLE.CARETAKER;
  return (
    <PageElem>
      <LayoutAppElem>
        {/* <HeaderContainer
          // icon={noteIcon}
          title="USER.USER.TITLE.NAME"
          size="default"
        /> */}
        <LayoutElem size="small">
          <LayoutSectionElem spacing={4}>
            {isUser && <ProfileUserTabContainer />}
            {isCaretaker && <ProfilePsychoTabContainer />}
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
      <DesktopMenuAppContainer />
      <LayoutFooterElem>
        <MobileMenuContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};
