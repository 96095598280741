import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { FieldTextElem } from '../../common/field-text';
import { FileUploaderElem } from '../../common/file-uploader';
import { FormElem } from '../../common/form';
import { LoaderElem } from '../../common/loader';
import { SelectElem } from '../../common/select';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { ANIMAL_TYPE_OPTION_LIST } from '../profile-psycho-update-specialization/constant';
import EditSvg from '../../asset/svg/button/edit.svg';
import { FORM_VALUE_ENUM } from '../profile-user-create-animal/constant';
import {
  ANIMAL_AGE_OPTION_LIST,
  ANIMAL_DATA_INTER,
  ANIMAL_TYPE_ENUM,
} from '../../data/animal/constant';
import { ToggleElem } from '../../common/toggle';
import { GENDER_OPTION_LIST } from '../profile-user-update-data/constant';

export const Component: React.FC<{
  data: ANIMAL_DATA_INTER;
  onToggle: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  setFieldValue: Function;
  getFieldError: Function;
  isFieldError: Function;
  setValue: Function;
  getFieldValue: Function;
  formik: FormikValues;
  handleSubmit: Function;
  isSubmitDisabled: Function;
  isError?: boolean;
  getErrorMessage: Function;
  isSuccess?: boolean;
  handleDelete: Function;
  isSuccessDelete: boolean;
  setPhotoState: Function;
  setCertificateState: Function;
  onDeletePhoto: Function;
  onDeleteCertificate: Function;
}> = ({
  data,
  onToggle,
  isOpen,
  isLoading,
  setFieldValue,
  getFieldError,
  isFieldError,
  setValue,
  getFieldValue,
  formik,
  handleSubmit,
  isSubmitDisabled,
  isError,
  getErrorMessage,
  isSuccess,
  handleDelete,
  isSuccessDelete,
  setPhotoState,
  setCertificateState,
  onDeletePhoto,
  onDeleteCertificate,
}) => {
  const isDogType =
    getFieldValue(FORM_VALUE_ENUM.TYPE) === ANIMAL_TYPE_ENUM.DOG;
  const isCatType =
    getFieldValue(FORM_VALUE_ENUM.TYPE) === ANIMAL_TYPE_ENUM.CAT;

  const isPassport = isDogType || isCatType;

  console.log('formik.values', formik.values);

  return (
    <>
      {isSuccessDelete && (
        <AlertActionElem type="success" tid="USER.PSYCHOLOGIST.DELETE" />
      )}
      {isLoading && <LoaderElem />}
      {!isOpen && (
        <CardContainer onClick={onToggle}>
          <TextElem
            type="medium"
            size="semiSmall"
            color="textPrimary"
            tid={data.typeView}
          />
          <img src={EditSvg} alt="edit" />
        </CardContainer>
      )}
      {isOpen && (
        <Container onSubmit={() => {}}>
          <FileUploaderElem
            title="ANIMAL.CREATE.PHOTO_TITLE"
            onFileUpload={(e) => {
              setPhotoState(e.file);
            }}
            fileObj={data?.photo}
            onRemove={onDeletePhoto}
            uploadType="image"
            dropZoneTitle="ANIMAL.CREATE.PHOTO_CONTENT_TITLE"
          />

          <SelectElem
            isDot
            placeholder="ANIMAL.CREATE.TYPE_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TYPE}
            onChange={setFieldValue}
            options={ANIMAL_TYPE_OPTION_LIST}
            title="ANIMAL.CREATE.TYPE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TYPE)}
            error={isFieldError(FORM_VALUE_ENUM.TYPE)}
            value={setValue(ANIMAL_TYPE_OPTION_LIST, FORM_VALUE_ENUM.TYPE)}
          />

          <FieldTextElem
            isDot
            name={FORM_VALUE_ENUM.NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onWheel={(e: any) => e.target.blur()}
            title="ANIMAL.CREATE.NAME"
            placeholder="ANIMAL.CREATE.NAME_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.NAME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
            error={isFieldError(FORM_VALUE_ENUM.NAME)}
          />

          <FieldTextElem
            isDot
            name={FORM_VALUE_ENUM.DESCRIPTION}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onWheel={(e: any) => e.target.blur()}
            title="ANIMAL.CREATE.DESCRIPTION"
            placeholder="ANIMAL.CREATE.DESCRIPTION_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
            error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
          />

          {isCatType && (
            <>
              <FieldTextElem
                name={FORM_VALUE_ENUM.CAT_BREED}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="ANIMAL.CREATE.CAT_BREED"
                placeholder="ANIMAL.CREATE.CAT_BREED"
                value={getFieldValue(FORM_VALUE_ENUM.CAT_BREED)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.CAT_BREED)}
                error={isFieldError(FORM_VALUE_ENUM.CAT_BREED)}
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.CAT_CAN_STAY_ALONE]}
                name={FORM_VALUE_ENUM.CAT_CAN_STAY_ALONE}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.CAT_CAN_STAY_ALONE"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.CAT_CAN_BITE]}
                name={FORM_VALUE_ENUM.CAT_CAN_BITE}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.CAT_CAN_BITE"
              />
              <SelectElem
                placeholder="ANIMAL.CREATE.CAT_AGE_PLACEHOLDER"
                name={FORM_VALUE_ENUM.CAT_AGE}
                onChange={setFieldValue}
                options={ANIMAL_AGE_OPTION_LIST}
                title="ANIMAL.CREATE.CAT_AGE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.CAT_AGE)}
                error={isFieldError(FORM_VALUE_ENUM.CAT_AGE)}
                value={setValue(
                  ANIMAL_AGE_OPTION_LIST,
                  FORM_VALUE_ENUM.CAT_AGE,
                )}
              />
              <SelectElem
                placeholder="ANIMAL.CREATE.GENDER_PLACEHOLDER"
                name={FORM_VALUE_ENUM.GENDER}
                onChange={setFieldValue}
                options={GENDER_OPTION_LIST}
                title="ANIMAL.CREATE.GENDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.GENDER)}
                error={isFieldError(FORM_VALUE_ENUM.GENDER)}
                value={setValue(GENDER_OPTION_LIST, FORM_VALUE_ENUM.GENDER)}
              />
            </>
          )}

          {isDogType && (
            <>
              <FieldTextElem
                name={FORM_VALUE_ENUM.DOG_BREED}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="ANIMAL.CREATE.DOG_BREED"
                placeholder="ANIMAL.CREATE.DOG_BREED_PLACEHOLDER"
                value={getFieldValue(FORM_VALUE_ENUM.DOG_BREED)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.DOG_BREED)}
                error={isFieldError(FORM_VALUE_ENUM.DOG_BREED)}
              />

              <ToggleElem
                checked={
                  formik.values[FORM_VALUE_ENUM.DOG_WEIGHT_OVER_THIRTYKG]
                }
                name={FORM_VALUE_ENUM.DOG_WEIGHT_OVER_THIRTYKG}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_WEIGHT_OVER_THIRTYKG"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_PULLS_LEASH]}
                name={FORM_VALUE_ENUM.DOG_PULLS_LEASH}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_PULLS_LEASH"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_PICKS_FROM_GROUND]}
                name={FORM_VALUE_ENUM.DOG_PICKS_FROM_GROUND}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_PICKS_FROM_GROUND"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_CANT_TAKE_AWAY]}
                name={FORM_VALUE_ENUM.DOG_CANT_TAKE_AWAY}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_CANT_TAKE_AWAY"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_CAN_STAY_ALONE]}
                name={FORM_VALUE_ENUM.DOG_CAN_STAY_ALONE}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_CAN_STAY_ALONE"
              />

              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE]}
                name={FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_IS_AGGRESSIVE"
              />

              {formik.values[FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE] && (
                <FieldTextElem
                  name={FORM_VALUE_ENUM.DOG_AGGRESSION_REASON}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="ANIMAL.CREATE.DOG_FEAR_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.DOG_AGGRESSION_REASON)}
                  errorMessage={getFieldError(
                    FORM_VALUE_ENUM.DOG_AGGRESSION_REASON,
                  )}
                  error={isFieldError(FORM_VALUE_ENUM.DOG_AGGRESSION_REASON)}
                />
              )}

              <SelectElem
                isDot
                placeholder="ANIMAL.CREATE.DOG_AGE_PLACEHOLDER"
                name={FORM_VALUE_ENUM.DOG_AGE}
                onChange={setFieldValue}
                options={ANIMAL_AGE_OPTION_LIST}
                title="ANIMAL.CREATE.DOG_AGE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.DOG_AGE)}
                error={isFieldError(FORM_VALUE_ENUM.DOG_AGE)}
                value={setValue(
                  ANIMAL_AGE_OPTION_LIST,
                  FORM_VALUE_ENUM.DOG_AGE,
                )}
              />

              <FieldTextElem
                name={FORM_VALUE_ENUM.DOG_FEAR}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="ANIMAL.CREATE.DOG_FEAR"
                placeholder="ANIMAL.CREATE.DOG_FEAR_PLACEHOLDER"
                value={getFieldValue(FORM_VALUE_ENUM.DOG_FEAR)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.DOG_FEAR)}
                error={isFieldError(FORM_VALUE_ENUM.DOG_FEAR)}
              />
            </>
          )}

          <ToggleElem
            checked={formik.values[FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS]}
            name={FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS}
            handleClick={formik.handleChange}
            tid="ANIMAL.CREATE.HAS_SPECIAL_NEEDS_TITLE"
          />

          {formik.values[FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS] && (
            <FieldTextElem
              isDot
              name={FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="ANIMAL.CREATE.SPECIAL_NEEDS_DESCRIPTION_PLACEHOLDER"
              value={getFieldValue(FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION)}
              errorMessage={getFieldError(
                FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION,
              )}
              error={isFieldError(FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION)}
            />
          )}

          {isPassport && (
            <>
              <FileUploaderElem
                title="ANIMAL.CREATE.CERTIFICATE_TITLE"
                onFileUpload={(e) => {
                  setCertificateState(e.file);
                }}
                onRemove={onDeletePhoto}
                fileObj={
                  data?.dogVaccinationPassport || data.catVaccinationPassport
                }
                uploadType="document"
                dropZoneTitle="ANIMAL.CREATE.CERTIFICATE_CONTENT_TITLE"
              />
            </>
          )}

          <TitleContainer>
            <Dot />
            <TextElem
              tid="USER.PSYCHOLOGIST.REQUIRED"
              type="light"
              size="input"
              color="textSecondary"
            />
          </TitleContainer>
          <ButtonContainer isDeleteFunc>
            <ButtonElem
              onClick={handleSubmit}
              type="submit"
              disabled={isSubmitDisabled()}
              tid="CATEGORY.FORM.BUTTON"
            />

            <ButtonElem
              disabled={isLoading}
              onClick={handleDelete}
              type="cancel"
              tid="CATEGORY.FORM.DELETE"
            />
          </ButtonContainer>
          {isError && <AlertActionElem text={getErrorMessage()} />}
          {isSuccess && (
            <AlertActionElem type="success" tid="CATEGORY.FORM.SUCCESS" />
          )}
        </Container>
      )}
    </>
  );
};

const ButtonContainer = styled.div<{ isDeleteFunc: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ isDeleteFunc }) =>
    isDeleteFunc ? '1fr 1fr' : '1fr'};
  grid-gap: ${Spacing(6)};
`;

const FileButtonContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
  align-self: start;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const Container = styled(FormElem)`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  align-items: start;
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: 19px ${Spacing(5)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
