import { USER_DATA_RAW } from '../../epic/profile-user-update-data/constant';
import { FILE_INTER } from '../file/constant';
import { GENDER_ENUM } from '../user/constant';

export enum ANIMAL_AGE {
  TWO_TO_SIX_MONTHS = '2-6m',
  SIX_MONTHS_TO_ONE_YEAR = '6m-1y',
  ONE_TO_SEVEN_YEARS = '1y-7y',
  SEVEN_YEARS_AND_ABOVE = '7y and above',
}

export const ANIMAL_AGE_OPTION_LIST = [
  {
    label: '2-6 місяці',
    value: ANIMAL_AGE.TWO_TO_SIX_MONTHS,
  },
  {
    label: '6м-1рік',
    value: ANIMAL_AGE.SIX_MONTHS_TO_ONE_YEAR,
  },
  {
    label: '1-7 років',
    value: ANIMAL_AGE.ONE_TO_SEVEN_YEARS,
  },
  {
    label: '7 років та більше',
    value: ANIMAL_AGE.SEVEN_YEARS_AND_ABOVE,
  },
];

export enum ANIMAL_TYPE_ENUM {
  DOG = 'dog',
  CAT = 'cat',
  BIRD = 'bird',
  RAT = 'rat',
  FERRET = 'ferret',
  REPTILE = 'reptile',
  RABBIT = 'rabbit',
  GUINEA_PIG = 'guinea_pig',
  HAMSTER = 'hamster',
  CHINCHILLA = 'chinchilla',
  OTHER = 'other',
}

export interface ANIMAL_RAW_INTER {
  id: string;
  user: USER_DATA_RAW;
  updateDate: string;
  createDate: string;
  type: ANIMAL_TYPE_ENUM;
  name: string;
  gender: GENDER_ENUM;
  photo?: FILE_INTER | null;
  description?: string;
  hasSpecialNeeds: boolean;
  specialNeedsDescription?: string;

  // Dog-specific fields
  dogBreed?: string;
  dogAge?: ANIMAL_AGE;
  dogWeightOverThirtykg: boolean;
  dogPullsLeash: boolean;
  dogPicksFromGround: boolean;
  dogCantTakeAway: boolean;
  dogCanStayAlone: boolean;
  dogFear?: string;
  dogIsAggressive: boolean;
  dogAggressionReason?: string;
  dogVaccinationPassport?: FILE_INTER | null;

  // Cat-specific fields
  catBreed?: string;
  catCanBite: boolean;
  catCanStayAlone: boolean;
  catAge?: ANIMAL_AGE;
  catVaccinationPassport?: FILE_INTER | null;
}

export interface ANIMAL_DATA_INTER extends Omit<ANIMAL_RAW_INTER, ''> {
  typeView?: string;
}
