import React, { useState } from 'react';
import styled from 'styled-components';

import { TabSecondaryElem } from '../../common/tab-secondary';
import { HeaderSecondary } from '../../common/header-secondary';
import { Spacing } from '../../theme';
import { ProfileUserUpdateContainer } from '../profile-user-update-data';
import { ProfileUserListAnimalContainer } from '../profile-user-animal-list';

export const Component: React.FC<{}> = ({}) => {
  const [active, setActive] = useState(1);

  const tabList = [
    { tid: 'USER.USER.TAB.INFO', value: 1 },
    { tid: 'USER.USER.TAB.ANIMAL', value: 2 },
  ];

  return (
    <Wrapper>
      <HeaderSecondary
        titleTid="USER.USER.TITLE.NAME"
        prefixTid="USER.USER.TITLE.PREFIX"
        sufixTid="USER.USER.TITLE.SUFIX"
      />
      {/* <TextElem
        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
        tid="USER.PSYCHOLOGIST.TITLE.TITLE"
        color="textPlaceholder"
        type="semi-bold"
        size="semiSmall"
      /> */}
      <TabSecondaryElem
        tabList={tabList}
        active={active}
        setActive={setActive}
      />
      {active === 1 && (
        <React.Fragment key="tab-1">
          <ProfileUserUpdateContainer />
        </React.Fragment>
      )}
      {active === 2 && (
        <React.Fragment key="tab-2">
          <ProfileUserListAnimalContainer />
        </React.Fragment>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};

  @media screen and (max-width: 1160px) {
    max-width: unset;
    margin-top: ${Spacing(8)};
    // margin-bottom: ${Spacing(25)};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;
