export const MODULE_NAME = 'PROFILE_USER_UPDATE_ANIMAL_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  MAIN: {
    TYPE: 'PATCH',
    URL: 'animal/update',
  },
  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string) => `animal/item/delete/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
