import { FormikValues } from 'formik';
import { required } from '../../lib/validation/service';
import { validation } from '../../lib/validation';

export const MODULE_NAME = 'PROFILE_USER_CREATE_ANIMAL_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  TYPE = 'type',
  NAME = 'name',
  PHOTO = 'photo',
  DESCRIPTION = 'description',
  HAS_SPECIAL_NEEDS = 'hasSpecialNeeds',
  SPECIAL_NEEDS_DESCRIPTION = 'specialNeedsDescription',
  DOG_BREED = 'dogBreed',
  DOG_AGE = 'dogAge',
  DOG_WEIGHT_OVER_THIRTYKG = 'dogWeightOverThirtyKg',
  DOG_PULLS_LEASH = 'dogPullsLeash',
  DOG_PICKS_FROM_GROUND = 'dogPicksFromGround',
  DOG_CANT_TAKE_AWAY = 'dogCantTakeAway',
  DOG_CAN_STAY_ALONE = 'dogCanStayAlone',
  DOG_FEAR = 'dogFear',
  DOG_IS_AGGRESSIVE = 'dogIsAggressive',
  DOG_AGGRESSION_REASON = 'dogAggressionReason',
  DOG_VACCINATION_PASSPORT = 'dogVaccinationPassport',
  CAT_BREED = 'catBreed',
  CAT_CAN_BITE = 'catCanBite',
  CAT_CAN_STAY_ALONE = 'catCanStayAlone',
  CAT_AGE = 'catAge',
  GENDER = 'gender',
  CAT_VACCINATION_PASSPORT = 'catVaccinationPassport',
}

export enum CATEGORY_ENUM {
  WALK = 1,
  FEED_CLEAN = 2,
  HOLD = 3,
}

export const config = {
  [FORM_VALUE_ENUM.TYPE]: [required],
  [FORM_VALUE_ENUM.NAME]: [required],
  [FORM_VALUE_ENUM.PHOTO]: [],
  [FORM_VALUE_ENUM.DESCRIPTION]: [required],
  [FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS]: [],
  [FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION]: [],
  [FORM_VALUE_ENUM.DOG_BREED]: [],
  [FORM_VALUE_ENUM.DOG_AGE]: [],
  [FORM_VALUE_ENUM.DOG_WEIGHT_OVER_THIRTYKG]: [],
  [FORM_VALUE_ENUM.DOG_PULLS_LEASH]: [],
  [FORM_VALUE_ENUM.DOG_PICKS_FROM_GROUND]: [],
  [FORM_VALUE_ENUM.DOG_CANT_TAKE_AWAY]: [],
  [FORM_VALUE_ENUM.DOG_CAN_STAY_ALONE]: [],
  [FORM_VALUE_ENUM.DOG_FEAR]: [],
  [FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE]: [],
  [FORM_VALUE_ENUM.DOG_AGGRESSION_REASON]: [],
  [FORM_VALUE_ENUM.DOG_VACCINATION_PASSPORT]: [],
  [FORM_VALUE_ENUM.CAT_BREED]: [],
  [FORM_VALUE_ENUM.CAT_CAN_BITE]: [],
  [FORM_VALUE_ENUM.CAT_CAN_STAY_ALONE]: [],
  [FORM_VALUE_ENUM.CAT_AGE]: [],
  [FORM_VALUE_ENUM.CAT_VACCINATION_PASSPORT]: [],
};

export const validate = (values: FormikValues) => validation(values, config);

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.TYPE]?: string;
  [FORM_VALUE_ENUM.NAME]?: string;
  [FORM_VALUE_ENUM.PHOTO]?: File | string;
  [FORM_VALUE_ENUM.DESCRIPTION]?: string;
  [FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS]?: boolean;
  [FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION]?: string;
  [FORM_VALUE_ENUM.DOG_BREED]?: string;
  [FORM_VALUE_ENUM.DOG_AGE]?: string;
  [FORM_VALUE_ENUM.DOG_WEIGHT_OVER_THIRTYKG]?: boolean;
  [FORM_VALUE_ENUM.DOG_PULLS_LEASH]?: boolean;
  [FORM_VALUE_ENUM.DOG_PICKS_FROM_GROUND]?: boolean;
  [FORM_VALUE_ENUM.DOG_CANT_TAKE_AWAY]?: boolean;
  [FORM_VALUE_ENUM.DOG_CAN_STAY_ALONE]?: boolean;
  [FORM_VALUE_ENUM.DOG_FEAR]?: string;
  [FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE]?: boolean;
  [FORM_VALUE_ENUM.DOG_AGGRESSION_REASON]?: string;
  [FORM_VALUE_ENUM.DOG_VACCINATION_PASSPORT]?: boolean;
  [FORM_VALUE_ENUM.CAT_BREED]?: string;
  [FORM_VALUE_ENUM.CAT_CAN_BITE]?: boolean;
  [FORM_VALUE_ENUM.CAT_CAN_STAY_ALONE]?: boolean;
  [FORM_VALUE_ENUM.CAT_AGE]?: string;
  [FORM_VALUE_ENUM.CAT_VACCINATION_PASSPORT]?: boolean;
}

export const API = {
  MAIN: {
    TYPE: 'POST',
    URL: 'animal/create',
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export interface FILE_STATE_INTER {
  id: string | number;
  file: File;
}
