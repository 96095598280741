import { ANIMAL_RAW_INTER } from '../../data/animal/constant';
import { ANIMALTYPE_VIEW } from '../profile-psycho/constant';

export const convert = (list: ANIMAL_RAW_INTER[]) => {
  if (!list || list.length < 1) return [];

  return list.map((e) => ({
    ...e,
    typeView: ANIMALTYPE_VIEW[e.type],
  }));
};
