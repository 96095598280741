import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { getAction } from './action';
import {
  ANIMAL_DATA_INTER,
  ANIMAL_RAW_INTER,
} from '../../data/animal/constant';
import { convert } from './convert';

export const Container: React.FC = () => {
  const [isCreateForm, setIsCreateForm] = useState(false);

  const {
    data: preFetchData,
    isSuccess: preFetchSuccess,
    isLoading: preFetchIsLoading,
    isError: preFetchIsError,
  } = useQuery(MODULE_NAME, getAction, {
    refetchOnWindowFocus: false,
  });

  const resetForm = () => {
    setIsCreateForm(false);
  };

  const action = useMutation(getAction, {});

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getData = (): ANIMAL_DATA_INTER[] => {
    const list = preFetchData as unknown as ANIMAL_RAW_INTER[];

    if (list && list.length > 0) {
      const converedData = convert(list);

      return converedData;
    }

    return [] as ANIMAL_DATA_INTER[];
  };

  return (
    <Component
      setIsCreateForm={setIsCreateForm}
      isCreateForm={isCreateForm}
      resetForm={resetForm}
      list={getData()}
      preFetchIsLoading={preFetchIsLoading}
      isSuccess={preFetchSuccess}
      isError={preFetchIsError}
      errorMessage={getErrorMessage()}
    />
  );
};
