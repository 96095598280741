import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { FieldTextElem } from '../../common/field-text';
import { FormElem } from '../../common/form';
import { SelectElem } from '../../common/select';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { FORM_VALUE_ENUM, FORM_VALUE_TYPE, CATEGORY_ENUM } from './constant';
import { LoaderElem } from '../../common/loader';
import {
  GENDER_OPTION_LIST,
  YES_NO_OPTION_LIST,
} from '../profile-user-update-data/constant';
import { FileUploaderElem } from '../../common/file-uploader';
import {
  ANIMAL_AGE_OPTION_LIST,
  ANIMAL_TYPE_ENUM,
} from '../../data/animal/constant';
import { ANIMAL_TYPE_OPTION_LIST } from '../profile-psycho-update-specialization/constant';
import { ToggleElem } from '../../common/toggle';

export const Component: React.FC<{
  onToggle: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  setPhoto: React.Dispatch<React.SetStateAction<File | null>>;
  // setDiplomaState: Function;
  // setAddDiplomaCount: React.Dispatch<React.SetStateAction<number>>;
  // setCertificateState: Function;
  // addCertificateCount: number;
  // setAddCertificateCount: React.Dispatch<React.SetStateAction<number>>;
  formik: FormikValues;
  getFieldValue: Function;
  getFieldError: Function;
  isFieldError: (name: FORM_VALUE_TYPE) => boolean;
  handleSubmit: Function;
  isSubmitDisabled?: boolean;
  isError?: boolean;
  getErrorMessage: Function;
  isSuccess?: boolean;
  setFieldValue: Function;
  setValue: Function;
  onDeletePhoto: Function;
  setCertificate: React.Dispatch<React.SetStateAction<File | null>>;
  onDeleteCertificate: Function;

  // addDiplomaCount: number;
  // onDeleteDiploma: Function;
  // onDeleteCertificate: Function;
  // isAddDiplomaDisabled: boolean;
  // isAddCertificateDisabled: boolean;
}> = ({
  onToggle,
  isOpen,
  isLoading,
  setPhoto,
  // setDiplomaState,
  // setAddDiplomaCount,
  // setCertificateState,
  // addCertificateCount,
  // setAddCertificateCount,
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  handleSubmit,
  isSubmitDisabled,
  isError,
  getErrorMessage,
  isSuccess,
  setFieldValue,
  setValue,
  // addDiplomaCount,
  // onDeleteDiploma,
  // onDeleteCertificate,
  onDeletePhoto,
  setCertificate,
  onDeleteCertificate,
  // isAddDiplomaDisabled,
  // isAddCertificateDisabled,
}) => {
  const isDogType =
    getFieldValue(FORM_VALUE_ENUM.TYPE) === ANIMAL_TYPE_ENUM.DOG;
  const isCatType =
    getFieldValue(FORM_VALUE_ENUM.TYPE) === ANIMAL_TYPE_ENUM.CAT;

  const isPassport = isDogType || isCatType;

  console.log('fromik', formik.values);

  return (
    <>
      {isLoading && <LoaderElem />}

      {isOpen && (
        <Container onSubmit={() => {}}>
          <SelectElem
            isDot
            placeholder="ANIMAL.CREATE.TYPE_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TYPE}
            onChange={setFieldValue}
            options={ANIMAL_TYPE_OPTION_LIST}
            title="ANIMAL.CREATE.TYPE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TYPE)}
            error={isFieldError(FORM_VALUE_ENUM.TYPE)}
            value={setValue(ANIMAL_TYPE_OPTION_LIST, FORM_VALUE_ENUM.TYPE)}
          />

          <FieldTextElem
            isDot
            name={FORM_VALUE_ENUM.NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onWheel={(e: any) => e.target.blur()}
            title="ANIMAL.CREATE.NAME"
            placeholder="ANIMAL.CREATE.NAME_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.NAME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
            error={isFieldError(FORM_VALUE_ENUM.NAME)}
          />

          <FieldTextElem
            isDot
            name={FORM_VALUE_ENUM.DESCRIPTION}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onWheel={(e: any) => e.target.blur()}
            title="ANIMAL.CREATE.DESCRIPTION"
            placeholder="ANIMAL.CREATE.DESCRIPTION_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
            error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
          />

          <FileUploaderElem
            title="ANIMAL.CREATE.PHOTO_TITLE"
            onFileUpload={(e) => {
              setPhoto(e.file);
            }}
            onRemove={onDeletePhoto}
            uploadType="image"
            dropZoneTitle="ANIMAL.CREATE.PHOTO_CONTENT_TITLE"
          />

          {isCatType && (
            <>
              <FieldTextElem
                name={FORM_VALUE_ENUM.CAT_BREED}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="ANIMAL.CREATE.CAT_BREED"
                placeholder="ANIMAL.CREATE.CAT_BREED"
                value={getFieldValue(FORM_VALUE_ENUM.CAT_BREED)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.CAT_BREED)}
                error={isFieldError(FORM_VALUE_ENUM.CAT_BREED)}
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.CAT_CAN_STAY_ALONE]}
                name={FORM_VALUE_ENUM.CAT_CAN_STAY_ALONE}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.CAT_CAN_STAY_ALONE"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.CAT_CAN_BITE]}
                name={FORM_VALUE_ENUM.CAT_CAN_BITE}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.CAT_CAN_BITE"
              />
              <SelectElem
                placeholder="ANIMAL.CREATE.CAT_AGE_PLACEHOLDER"
                name={FORM_VALUE_ENUM.CAT_AGE}
                onChange={setFieldValue}
                options={ANIMAL_AGE_OPTION_LIST}
                title="ANIMAL.CREATE.CAT_AGE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.CAT_AGE)}
                error={isFieldError(FORM_VALUE_ENUM.CAT_AGE)}
                value={setValue(
                  ANIMAL_AGE_OPTION_LIST,
                  FORM_VALUE_ENUM.CAT_AGE,
                )}
              />
              <SelectElem
                placeholder="ANIMAL.CREATE.GENDER_PLACEHOLDER"
                name={FORM_VALUE_ENUM.GENDER}
                onChange={setFieldValue}
                options={GENDER_OPTION_LIST}
                title="ANIMAL.CREATE.GENDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.GENDER)}
                error={isFieldError(FORM_VALUE_ENUM.GENDER)}
                value={setValue(GENDER_OPTION_LIST, FORM_VALUE_ENUM.GENDER)}
              />
            </>
          )}

          {isDogType && (
            <>
              <FieldTextElem
                name={FORM_VALUE_ENUM.DOG_BREED}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="ANIMAL.CREATE.DOG_BREED"
                placeholder="ANIMAL.CREATE.DOG_BREED_PLACEHOLDER"
                value={getFieldValue(FORM_VALUE_ENUM.DOG_BREED)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.DOG_BREED)}
                error={isFieldError(FORM_VALUE_ENUM.DOG_BREED)}
              />

              <ToggleElem
                checked={
                  formik.values[FORM_VALUE_ENUM.DOG_WEIGHT_OVER_THIRTYKG]
                }
                name={FORM_VALUE_ENUM.DOG_WEIGHT_OVER_THIRTYKG}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_WEIGHT_OVER_THIRTYKG"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_PULLS_LEASH]}
                name={FORM_VALUE_ENUM.DOG_PULLS_LEASH}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_PULLS_LEASH"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_PICKS_FROM_GROUND]}
                name={FORM_VALUE_ENUM.DOG_PICKS_FROM_GROUND}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_PICKS_FROM_GROUND"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_CANT_TAKE_AWAY]}
                name={FORM_VALUE_ENUM.DOG_CANT_TAKE_AWAY}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_CANT_TAKE_AWAY"
              />
              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_CAN_STAY_ALONE]}
                name={FORM_VALUE_ENUM.DOG_CAN_STAY_ALONE}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_CAN_STAY_ALONE"
              />

              <ToggleElem
                checked={formik.values[FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE]}
                name={FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE}
                handleClick={formik.handleChange}
                tid="ANIMAL.CREATE.DOG_IS_AGGRESSIVE"
              />

              {formik.values[FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE] && (
                <FieldTextElem
                  name={FORM_VALUE_ENUM.DOG_AGGRESSION_REASON}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="ANIMAL.CREATE.DOG_FEAR_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.DOG_AGGRESSION_REASON)}
                  errorMessage={getFieldError(
                    FORM_VALUE_ENUM.DOG_AGGRESSION_REASON,
                  )}
                  error={isFieldError(FORM_VALUE_ENUM.DOG_AGGRESSION_REASON)}
                />
              )}

              <SelectElem
                isDot
                placeholder="ANIMAL.CREATE.DOG_AGE_PLACEHOLDER"
                name={FORM_VALUE_ENUM.DOG_AGE}
                onChange={setFieldValue}
                options={ANIMAL_AGE_OPTION_LIST}
                title="ANIMAL.CREATE.DOG_AGE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.DOG_AGE)}
                error={isFieldError(FORM_VALUE_ENUM.DOG_AGE)}
                value={setValue(
                  ANIMAL_AGE_OPTION_LIST,
                  FORM_VALUE_ENUM.DOG_AGE,
                )}
              />

              <FieldTextElem
                name={FORM_VALUE_ENUM.DOG_FEAR}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="ANIMAL.CREATE.DOG_FEAR"
                placeholder="ANIMAL.CREATE.DOG_FEAR_PLACEHOLDER"
                value={getFieldValue(FORM_VALUE_ENUM.DOG_FEAR)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.DOG_FEAR)}
                error={isFieldError(FORM_VALUE_ENUM.DOG_FEAR)}
              />
            </>
          )}

          <ToggleElem
            checked={formik.values[FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS]}
            name={FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS}
            handleClick={formik.handleChange}
            tid="ANIMAL.CREATE.HAS_SPECIAL_NEEDS_TITLE"
          />

          {formik.values[FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS] && (
            <FieldTextElem
              isDot
              name={FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="ANIMAL.CREATE.SPECIAL_NEEDS_DESCRIPTION_PLACEHOLDER"
              value={getFieldValue(FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION)}
              errorMessage={getFieldError(
                FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION,
              )}
              error={isFieldError(FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION)}
            />
          )}

          {isPassport && (
            <FileUploaderElem
              title="ANIMAL.CREATE.CERTIFICATE_TITLE"
              onFileUpload={(e) => {
                setCertificate(e.file);
              }}
              onRemove={onDeleteCertificate}
              uploadType="document"
              dropZoneTitle="ANIMAL.CREATE.CERTIFICATE_CONTENT_TITLE"
            />
          )}

          {/* <FileButtonContainer>
            {Array.from({ length: addDiplomaCount }).map((_, index) => (
              <FileUploaderElem
                isDot={index === 0 ? true : false}
                title={index === 0 ? 'COMMON.FILE.DIPLOMA_TITLE' : ''}
                key={index}
                onFileUpload={(e) => {
                  setDiplomaState(e);
                }}
                onRemove={onDeleteDiploma}
                uploadType="document"
                dropZoneTitle="COMMON.FILE.DIPLOMA_CONTENT_TITLE"
              />
            ))}
            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              disabled={isAddDiplomaDisabled || addDiplomaCount >= 6}
              type="file"
              onClick={() => {
                if (addDiplomaCount < 6) {
                  setAddDiplomaCount((prev) => prev + 1);
                }
              }}
            />
          </FileButtonContainer> */}
          {/* 
          <FileButtonContainer>
            {Array.from({ length: addCertificateCount }).map((_, index) => (
              <FileUploaderElem
                isDot={index === 0 ? true : false}
                title={index === 0 ? 'COMMON.FILE.CERTIFICATE_TITLE' : ''}
                key={index}
                onFileUpload={(e) => {
                  setCertificateState(e);
                }}
                onRemove={onDeleteCertificate}
                uploadType="document"
                dropZoneTitle="COMMON.FILE.CERTIFICATE_CONTENT_TITLE"
              />
            ))}
            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              disabled={isAddCertificateDisabled || addCertificateCount >= 6}
              type="file"
              onClick={() => {
                if (addCertificateCount < 6) {
                  setAddCertificateCount((prev) => prev + 1);
                }
              }}
            />
          </FileButtonContainer> */}

          <TitleContainer>
            <Dot />
            <TextElem
              tid="ANIMAL.CREATE.REQUIRED"
              type="light"
              size="input"
              color="textSecondary"
            />
          </TitleContainer>
          <ButtonElem
            onClick={handleSubmit}
            type="submit"
            disabled={isSubmitDisabled}
            tid="CATEGORY.FORM.BUTTON"
          />
          {isError && <AlertActionElem text={getErrorMessage()} />}
          {isSuccess && (
            <AlertActionElem type="success" tid="CATEGORY.FORM.SUCCESS" />
          )}
        </Container>
      )}
    </>
  );
};

const FileButtonContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
  align-self: start;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const Container = styled(FormElem)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
`;
