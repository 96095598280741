import dayjs from 'dayjs';
import { OPTION_INTER } from '../../data/user/constant';

export const convert = (values: any) => {
  const animalType = values?.workAnimalType
    ? values?.workAnimalType?.map((e: OPTION_INTER) => e.value).join(',')
    : null;

  return {
    ...values,
    workAnimalType: animalType,
    specialistWith: values?.specialistWith
      ?.map((e: OPTION_INTER) => e.value)
      .join(','),
    time: values?.time?.map((e: OPTION_INTER) => e.value).join(','),
  };
};

export const convertDate = (strDate: any) => {
  if (strDate == '' || strDate == null || strDate == undefined) {
    return null;
  }
  const date = new Date(strDate);
  return dayjs(date);
};
